.v-toast {
}
.v-toast [class*="modal-dialog"] {
  max-width: 300px;
  align-items: flex-start;
}
.v-toast [class*="modal-content"] {
  border: 1px solid #6fcf97;
  box-shadow: 0px 2px 26px rgba(82, 215, 0, 0.1);
}
.v-toast-red [class*="modal-content"] {
  border: 1px solid red;
  box-shadow: 0px 2px 26px rgba(255, 0, 0, 0.1);
}
.v-toast [class*="modal-body"] {
  display: flex;
}
.v-toast-img {
  margin-right: 1rem;
}
.v-toast-ttl {
  color: #333333;
  font-weight: 600;
  margin-bottom: 4px;
}
.v-toast-txt {
  color: #4d4d4d;
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 300;
}
.v-toast-red-icon {
  border: 2px solid red;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: red;
  font-weight: 800;
  margin-right: 1rem;
}
