.productWrapper {
  padding-top: 5rem;
  padding-bottom: 4rem;
}
.title {
  text-align: center;
  text-transform: capitalize;
  color: #323b4b;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.text {
  text-align: center;
  max-width: 470px;
  margin: 0 auto 1.6rem;
  line-height: 2;
  font-size: 16px;
}

/* Product Item */
.productItem {
  display: flex;
  flex-wrap: wrap;
  padding: 4.5rem 0;
}
.imageSection {
  width: 50%;
  text-align: right;
  padding-top: 3rem;
  position: relative;
  height: fit-content;
}
.imageSection img {
  width: 90%;
  margin-left: auto;
  position: relative;
  z-index: 2;
}
.imageSection::before {
  content: "";
  background-image: url("../../../assets/vectors/leftShadow.svg");
  position: absolute;
  top: -3%;
  left: 1%;
  z-index: 1;
  width: 64%;
  height: auto;
  display: block;
  height: 90%;
  background-repeat: no-repeat;
  background-size: contain;
}
.textSection {
  width: 50%;
  padding-top: 3rem;
  padding-left: 2rem;
}
.textSection > div:first-of-type {
  background: #fff3cc;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0px 4px 6px rgb(17 17 17 / 8%);
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.textSection > div:first-of-type > img {
  width: 60%;
}
.textSection > h5 {
  color: #33869d;
  font-weight: 700;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}
.textSection > p {
  line-height: 2;
  width: 90%;
  font-size: 15px;
  margin-bottom: 1.5rem;
}
.textSection > ul {
  padding: 0;
  padding-top: 12px;
}
.textSection > ul > li {
  font-size: 15px;
  list-style: none;
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 1.8rem;
}
.textSection > ul > li::before {
  content: url("../../../assets/vectors/tickGreenCircle.svg");
  position: absolute;
  left: 0;
}
.textSection > a {
  color: #33869d;
  text-decoration: underline !important;
  font-weight: 500;
  margin-top: 3rem;
  display: block;
  font-size: 13.5px;
}
.textSection > a:hover {
  color: #33869d;
  text-decoration: underline;
  transform: scale(1.05);
}
.reverse {
  flex-direction: row-reverse;
}
.reverse .textSection {
  padding-left: 0;
  padding-right: 2rem;
}
.reverse .imageSection {
  text-align: left;
}
.reverse .imageSection::before {
  background-image: url("../../../assets/vectors/rightShadow.svg");
  position: absolute;
  left: unset;
  right: 1%;
  z-index: 1;
}
.comingSoon {
  color: #FFAB00;
  border: 1px solid #FFAB00;
  background: #FDF4E0;
  border-radius: 6px;
  margin-top: auto;
  width: fit-content;
  margin-bottom: 6px;
  padding: 12px 1.8rem;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
} 

@media screen and (max-width: 1000px) {
  .productItem {
    flex-direction: column;
    max-width: 700px;
    margin: auto;
  }
  .imageSection {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  .textSection {
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .productWrapper {
    padding-top: 2rem;
    padding-bottom: 2.2rem;
  }
  .title {
    text-align: left;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  .text {
    text-align: left;
    margin-left: 0;
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 10px;
  }
  .productItem {
    padding: 3rem 0 1rem;
  }
  .textSection > p {
    font-size: 14px;
    line-height: 1.8;
    width: 100%;
  }
  .textSection > ul {
    padding: 0;
  }
  .textSection > ul > li {
    font-size: 14px;
    margin-bottom: 1.3rem;
  }
  .textSection > a {
    font-size: 14.5px;
  }
}