.base {
  border: none;
  padding: 13px 2.35rem;
  border-radius: 10px;
  margin-left: auto;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.green {
  color: #fff;
  background: #33869d;
}
.white {
  background: #fff;
  color: #33869d;
}
.large {
  padding: 16px 3.6rem;
  font-size: 18px;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 600px) {
  .large {
    font-size: 15px;
  }
}