.sectionWrapper {
  display: flex;
  padding-top: 6rem;
  padding-bottom: 4rem;
  justify-content: space-between;
  align-items: center;
}
.sectionWrapper > div {
  width: 42%;
}
.sectionWrapper > div > h6 {
  line-height: 2;
  color: #33869d;
  font-weight: 700;
  font-size: 1.4rem;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}
.sectionWrapper > div > p {
  line-height: 2.3;
  letter-spacing: 0.3px;
}
.sectionWrapper > div > p > span {
  font-weight: 600;
}
.sectionWrapper img {
  width: 56%;
}

@media screen and (max-width: 900px) {
  .sectionWrapper {
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  .sectionWrapper > div {
    width: 100%;
  }
  .sectionWrapper img {
    max-width: 600px;
    width: 100%;
    margin-top: 2rem;
  }
}