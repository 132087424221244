.heroContainer {
  display: flex;
  position: relative;
}
.textSection {
  width: 50%;
  padding-top: 2.6rem;
  padding-bottom: 3rem;
}
.textSection h1 {
  font-weight: 700;
  color: #323b4b;
  line-height: 1.4;
  text-transform: capitalize;
  letter-spacing: 0.4px;
  margin-bottom: 1rem;
}
.textSection h1 span {
  color: #33869d;
  text-transform: capitalize;
}
.textSection p:first-of-type {
  line-height: 2;
  font-size: 1rem;
  letter-spacing: 0.1px;
  margin-bottom: 1.8rem;
  font-weight: 400;
  width: 95%;
}
.textSection p:last-of-type {
  margin-top: 2.2rem;
  font-size: 11px;
  letter-spacing: 0.2px;
}
.logoSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 400px;
}
.logoSection svg {
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.imageSection {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  width: 47vw;
  position: absolute;
  top: -120px;
  right: -129px;
  max-width: 650px;
}
.imageSection div {
  width: 25%;
  /* height: 120px; */
  aspect-ratio: 1;
}
.imageSection > div:nth-child(1),
.imageSection > div:nth-child(2),
.imageSection > div:nth-child(4),
.imageSection > div:nth-child(11),
.imageSection > div:nth-child(12) {
  background: #ebf3f5;
}
.imageSection > div:nth-child(6),
.imageSection > div:nth-child(8),
.imageSection > div:nth-child(10),
.imageSection > div:nth-child(13) {
  background: #33869d;
}

.imageSection > div:nth-child(3) {
  background-color: #000;
  background-image: url("../../../assets/images/heroImage1.png");
  background-size: contain;
}

.imageSection > div:nth-child(5) {
  background-color: #000;
  background-image: url("../../../assets/images/heroImage2.png");
  background-size: contain;
}

.imageSection > div:nth-child(7) {
  background-color: #000;
  background-image: url("../../../assets/images/heroImage3.png");
  background-size: contain;
}

.imageSection > div:nth-child(9) {
  background-color: #000;
  background-image: url("../../../assets/images/heroImage4.png");
  background-size: contain;
}

.imageSection > div:nth-child(1),
.imageSection > div:nth-child(2),
.imageSection > div:nth-child(5),
.imageSection > div:nth-child(9) {
  border-top-left-radius: 100%;
}

.imageSection > div:nth-child(3),
.imageSection > div:nth-child(8),
.imageSection > div:nth-child(10),
.imageSection > div:nth-child(12) {
  border-bottom-left-radius: 100%;
}

.imageSection > div:nth-child(4),
.imageSection > div:nth-child(7),
.imageSection > div:nth-child(11),
.imageSection > div:nth-child(13) {
  border-top-right-radius: 100%;
}

.imageSection > div:nth-child(6) {
  border-bottom-right-radius: 100%;
}

.imageSection > div:nth-child(1) {
  margin-left: 50%;
  margin-right: 25%;
}
.imageSection > div:nth-child(2) {
  margin-left: 25%;
}
.imageSection > div:nth-child(7) {
  margin-right: 25%;
}
.imageSection > div:nth-child(12) {
  margin-left: 25%;
}

@media screen and (max-width: 1400px) {
  .heroContainer {
    position: unset;
  }
  .imageSection {
    right: 0;
    top: 0;
  }
}

@media screen and (max-width: 1200px) {
  .textSection h1 {
    font-size: 2.2rem;
    max-width: 430px;
  }
  .heroContainer {
    position: relative;
  }
  .imageSection {
    top: 2rem;
  }
}

@media screen and (max-width: 900px) {
  .heroContainer {
    flex-wrap: wrap;
    padding-bottom: 4rem;
  }
  .textSection {
    width: 100%;
    padding-bottom: 0;
  }
  .imageSection {
    position: relative;
    width: 100%;
    margin: auto;
    margin-right: -1rem;
    top: -1rem;
  }
  .textSection h1 {
    max-width: 600px;
    line-height: 2;
  }
  .textSection p:first-of-type {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  .textSection h1 {
    font-size: 1.5rem;
    max-width: 400px;
  }
  .textSection p:first-of-type {
    width: 100%;
    font-size: 14px;
  }
  .logoSection svg:nth-of-type(1),
  .logoSection svg:nth-of-type(2),
  .logoSection svg:nth-of-type(3) {
    width: 28%;
  }
  .heroContainer {
    padding-bottom: 1rem;
  }
}
