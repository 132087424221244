.wrapper {
  height: 100vh;
  background: #fafbfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
  justify-content: center;
  min-height: 800px;
}
.logo {
  position: absolute;
  top: 3rem;
  left: 3rem;
}
.error404 {
  width: 90%;
  max-width: 600px;
  margin-bottom: 5rem;
}
.text {
  color: #4e4b66;
  margin-bottom: 2rem;
  letter-spacing: 0.01em;
  text-align: center;
}
.btn {
  background: #33869d;
  border-radius: 6px;
  color: #fff;
  padding: 10px 2rem;
  border: none;
  font-size: 14px;
  width: 90%;
  max-width: 400px;
}
@media screen and (max-width: 600px) {
  .logo {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 40px;
  }
  .wrapper {
    padding-top: 0;
    justify-content: center;
  }
}
