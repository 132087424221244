.statContainer {
  display: flex;
  padding-top: 6rem;
  padding-bottom: 8rem;
  align-items: center;
}
.textSection {
  width: 50%;
}
.textSection p:first-child {
  color: #33869d;
  font-weight: 700;
  font-size: 16px;
}
.textSection h2 {
  color: #323b4b;
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 1.35;
  max-width: 400px;
  margin-bottom: 1.2rem;
}
.textSection p:nth-last-child(1) {
  line-height: 2;
  font-size: 16px;
  max-width: 450px;
}
.statSection {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
}

/* Stat */
.stat {
  width: fit-content;
  width: 50%;
  margin-bottom: 2rem;
  padding-left: 5%;
}
.stat p:nth-child(1) {
  color: #33869d;
  font-weight: 700;
  font-size: 3.7rem;
  margin-bottom: 0;
}
.stat p:nth-child(2) {
  color: #323b4b;
  font-size: 16px;
}

@media screen and (max-width: 1200px) {
  .textSection h2 {
    font-size: 2.2rem;
  }
  .textSection p:first-child {
    font-size: 14px;
    font-weight: 600;
  }
  .textSection p:last-child {
    font-size: 14px;
  }
  .stat p:nth-child(1) {
    font-size: 3rem;
  }
  .stat p:nth-child(2) {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .statContainer {
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .textSection {
    width: 100%;
  }
  .statSection {
    width: 100%;
  }
  .textSection p:last-child {
    max-width: 600px;
    line-height: 2.2;
  }
  .textSection h2 {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 1rem;
  }
  .stat {
    padding-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .textSection h2 {
    font-size: 1.6rem;
  }
  .stat {
    margin-bottom: 1.5rem;
  }
  .stat p:nth-child(1) {
    font-size: 2.5rem;
  }
  .stat p:nth-child(2) {
    font-size: 13px;
  }
}