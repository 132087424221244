.heroContainer {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 4rem;
  justify-content: space-between;
}
.heroContainer > div {
  width: 42%;
  padding-top: 4rem;
}
.heroContainer > div > h1 {
  line-height: 2;
  font-weight: 700;
  color: #323b4b;
  text-transform: uppercase;
}
.heroContainer > div > h1 > span {
  color: #33869d;
}
.heroContainer > div > p {
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.3px;
}
.heroContainer img {
  width: 56%;
}

@media screen and (max-width: 1200px) {
  .heroContainer > div > h1 {
    font-size: 2.2rem;
  }
  .heroContainer > div {
    width: 50%;
  }
  .heroContainer img {
    width: 50%;
  }
}
@media screen and (max-width: 900px) {
  .heroContainer {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 2rem;
  }
  .heroContainer > div {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }
  .heroContainer img {
    width: 100%;
    margin: auto;
    max-width: 550px;
  }
  .heroContainer > div > h1 {
    font-size: 1.8rem;
  }
  .heroContainer > div > p {
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .heroContainer {
    padding-bottom: 1rem;
  }
  .heroContainer > div > h1 {
    text-transform: capitalize;
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 400px) {

}