* {
  box-sizing: border-box;
  transition: 0.3s;
  scroll-behavior: smooth;
}

/* Euclid Circular */
@font-face {
  font-family: "Euclid";
  font-weight: 300;
  font-display: swap;
  src: url("fonts/Euclid/Euclid\ Circular\ A\ Light.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid";
  font-weight: 400;
  font-display: swap;
  src: url("fonts/Euclid/Euclid\ Circular\ A\ Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid";
  font-weight: 500;
  font-display: swap;
  src: url("fonts/Euclid/Euclid\ Circular\ A\ Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid";
  font-weight: 600;
  font-display: swap;
  src: url("fonts/Euclid/Euclid\ Circular\ A\ SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Euclid";
  font-weight: 700;
  font-display: swap;
  src: url("fonts/Euclid/Euclid\ Circular\ A\ Bold.ttf") format("truetype");
}

/* Helvetica Neue */
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 400;
  font-display: swap;
  src: url("fonts/Helvetica/HelveticaNeue-Extended.otf") format("opentype");
}
@font-face {
  font-family: "Helvetica Neue";
  font-weight: 500;
  font-display: swap;
  src: url("fonts/Helvetica/HelveticaNeue-Medium.otf") format("opentype");
}

html body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;
  height: 100%;
  font-family: "Euclid";
  font-weight: 300;
  font-size: 14px;
  color: #8A94A6;
}
@media (max-width: 700px) {
  body {
    font-size: 14px;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: 0.3s;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #33869d;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #33869d;
}

button,
input,
textarea,
select {
  font-family: "Euclid";
  outline: none !important;
}

.appContainer {
  /* padding-left: 4rem; */
  /* padding-right: 4rem; */
  max-width: 1100px;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .appContainer {
    padding-left: 4% !important;
    padding-right: 4% !important;
  }
}

html a {
  color: inherit;
  text-decoration: none !important;
}

[class*="modal-content"] {
  border: none !important;
  border-radius: 12px !important;
}