.fauxWhite {
  background: #fafbfc;
  padding-top: 120px;
}

.white {
  background: #fff;
  padding-top: 120px;
}

@media screen and (max-width: 900px) {
  .fauxWhite,
  .white {
    padding-top: 88px;
  }
}
