.sectionBg {
  background: linear-gradient(180deg, #ebf3f5 0%, rgba(235, 243, 245, 0) 100%);
}
.sectionWrapper {
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-left: calc(50vw - 550px);
}
.sectionWrapper > h6 {
  line-height: 2;
  color: #33869d;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 1100px;
}
.sectionWrapper > div {
  margin: 2.5rem auto 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  overflow-x: scroll;
  padding-bottom: 2rem;
}

/* News Card */
.card {
  width: 23.8%;
  background: #ffffff;
  box-shadow: 0px 12px 16px -4px rgb(16 24 40 / 8%), 0px 4px 6px -2px rgb(16 24 40 / 3%);
  padding: 1rem;
  min-width: 260px;
  margin-right: 1.2rem;
  padding-bottom: 1.8rem;
  display: flex;
  flex-direction: column;
}
.card img {
  width: 100%;
}
.card p:first-of-type {
  color: #4e5d78;
  font-weight: 500;
  margin-top: 1rem;
  margin-bottom: 9px;
  font-size: 14.5px;
  width: 102%;
}
.card p:last-of-type {
  font-size: 11px;
}
.sectionWrapper > div > .card:nth-last-of-type(2) p:last-of-type {
  margin-top: auto;
}
/* .card:nth-child(1) svg {
  width: 66px;
}
.card:nth-child(2) svg {
  width: 80px;
}
.card:nth-child(3) svg {
  width: 74px;
}
.card:nth-child(4) svg {
  width: 105px;
} */
img.logo {
  width: fit-content;
  margin-top: auto;
  max-width: 100px;
}
svg.logo {
  margin-top: auto;
}



@media screen and (min-width: 1600px) {
  .sectionWrapper {
    margin-left: calc(50vw - 700px);
  }
}

@media screen and (min-width: 2000px) {
  .sectionWrapper {
   margin: auto;
  }
  .sectionWrapper > div {
    justify-content: center;
  }
  .card {
    max-width: 300px;
  }
}

@media screen and (max-width: 1200px) {
  .sectionWrapper {
    margin: auto;
    padding-left: 4%;
    padding-right: 4%;
  }
  .sectionWrapper > div {
    flex-wrap: wrap;
  }
  .card {
    min-width: 250px;
    margin-bottom: 2rem;
    width: 46%;
    max-width: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .card {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 600px) {
  .card {
    min-width: 250px;
    margin-bottom: 2rem;
    width: 90%;
    max-width: 400px;
  }
  .sectionWrapper {
    padding-bottom: 1rem;
  }
}