.sectionBg {
  background: linear-gradient(180deg, #ebf3f5 0%, rgba(235, 243, 245, 0) 100%);
}
.sectionWrapper {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
}
.sectionWrapper > h6 {
  line-height: 2;
  color: #33869d;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
}
.sectionWrapper > div {
  width: 100%;
  max-width: 750px;
  background: #ebf3f5;
  margin: 2.5rem auto 1rem;
  padding: 1rem;
  aspect-ratio: 7/4;
}

@media screen and (max-width: 600px) {
  .sectionWrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sectionWrapper > h6 {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    line-height: normal;
    margin-bottom: 2rem;
  }
  .sectionWrapper > div {
    padding: 10px;
  }
}