.survey [class*="modal-dialog"] {
  max-width: 600px;
}
.survey [class*="modal-content"] {
  background: #fafbfc;
}
.survey .surveyBody {
  padding: 3rem 2rem;
  text-align: center;
  border-radius: inherit;
  max-width: 500px;
  margin: auto;
}
.surveyBody h1 {
  color: #33869d;
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}
.surveyBody > p {
  margin-bottom: 2.5rem;
  font-size: 15px;
}
.formContainer {
}
input,
textarea {
  border: 1px solid #c1c7d0;
  background: transparent;
  border-radius: 8px;
  padding-left: 10px;
  width: 100%;
  margin-bottom: 1.2rem !important;
}
input {
  height: 48px;
}
textarea {
  padding: 1rem;
  max-height: 150px;
  min-height: 150px;
}
input::placeholder,
textarea::placeholder {
  color: #c1c7d0;
  font-weight: 300;
}
.btn {
  width: 100%;
  margin-top: 1rem;
}
.dropdownProducts {
  text-align: left;
}
.dropdownProducts input {
  height: 100%;
}
.dropdownProducts [class*="surveySelect__control"] {
  height: 48px;
  border-radius: 8px;
  border: 1px solid #c1c7d0;
  padding-left: 1rem;
  font-size: 14px;
  /* color: #323b4b; */
  width: 100%;
  margin-bottom: 1.2rem;
  background: transparent;
}
.dropdownProducts [class*="surveySelect__control--is-focused"]:hover {
  box-shadow: none;
  border-color: #c1c7d0;
}
.dropdownProducts [class*="surveySelect__control--is-focused"] {
  box-shadow: none;
}
.dropdownProducts [class*="surveySelect__indicator-separator"] {
  display: none;
}
.dropdownProducts [class*="surveySelect__placeholder"] {
  visibility: visible !important;
}

.dropdownProducts [class*="surveySelect__value-container"] {
  height: 100%;
  padding: 0;
}

.dropdownProducts [class*="surveySelect__menu"] {
  z-index: 2;
}

.empty {
  border-color: red;
}
p.message {
  margin: 1rem 0 0;
  font-size: 14px;
  color: red;
}
.empty [class*="surveySelect__control"] {
  border-color: red;
}
.inputMessage {
  text-align: left;
  font-size: 10px;
  margin-top: -1rem;
  color: red;
}
