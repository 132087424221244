.sectionBg {
  background: linear-gradient(180deg, #ebf3f5 0%, rgba(235, 243, 245, 0) 100%);
}
.sectionWrapper {
  padding-top: 4rem;
  padding-bottom: 6rem;
}
.sectionWrapper > h6 {
  line-height: 2;
  color: #33869d;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;
  text-align: center;
}
.sectionWrapper .cardWrapper {
  margin: 2.5rem auto 1rem;
  display: flex;
  justify-content: space-between;
}

/* News Card */
.card {
  width: 32%;
  background: #f2f2f3;
  border: 4px solid #ffffff;
  padding: 1.5rem;
  border-radius: 2px;
}
.card > div {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.card > div > p {
  font-size: 12px;
  margin: 0;
  padding-left: 1rem;
}
.card > div > p > span {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: normal;
  color: #231c4e;
  font-family: "Helvetica Neue";
  margin-bottom: 2px;
  display: inline-block;
}
.card > p {
  line-height: 1.8;
  margin-bottom: 0;
}
.cardControl {
  display: none;
}

@media screen and (max-width: 1200px) {
  .sectionWrapper .cardWrapper {
    overflow: hidden;
    overflow-x: scroll;
  }
  .card {
    min-width: 350px;
    margin-right: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .card {
    min-width: 80%;
    margin: 0 10%;
  }
  .sectionWrapper {
    padding-top: 1rem;
    padding-bottom: 4rem;
  }
  .sectionWrapper  .cardWrapper {
    margin-top: 1.5rem;
  }
  .cardControl {
    display: flex;
    justify-content: space-between;
    width: 40px;
    margin: 1.5rem auto 0;
  }
  .cardControl > div {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #c1c7d0;
  }
  .cardControl > div::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    border: 1px solid transparent;
    margin-left: -3px;
    margin-top: -3px;
  }
  div.activeCard {
    background-color: #33869d;
  }
  div.activeCard::before {
    border-color: #33869d;
  }
}

@media screen and (max-width: 450px) {
  .card {
    min-width: 96%;
    margin: 0 2%;
  }
}
