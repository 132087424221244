.navWrapper {
  display: flex;
  align-items: center;
  padding: 2rem 0;
  background: transparent;
  position: relative;
  z-index: 1;
}
.fixedNav {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.addBackground {
  background: #fff;
  z-index: 1000;
}
.addBackground .navWrapper {
  padding: 1rem;
}
.logo {
  width: 114px;
}
.navItemsWrapper {
  margin-left: 12%;
  display: flex;
}
.navItems {
  margin: 0 1.8rem 0 1rem;
  color: #8a94a690;
  font-size: 15px;
}
button.navItems {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
}
.navItems:hover,
.navItemsActive,
.showProducts {
  color: #33869d;
  font-weight: 500;
}
button.navItems svg {
  margin-left: 6px;
  width: 14px;
  /* margin-bottom: 4px; */
}
button.navItems:hover svg path {
  stroke: #33869d;
  stroke-width: 2;
}
.showProducts svg {
  transform: rotate(180deg);
}
.showProducts svg path {
  stroke: #33869d;
  stroke-width: 2;
}

/* Products Dropdown */
.navParent {
  background: #fff;
  box-shadow: 0px 34px 54px rgba(0, 0, 0, 0.12);
  animation: showProductList 0.9s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
.hideNav {
  position: absolute;
  top: -1000px;
  opacity: 0;
}
.navParentHide {
  background: #fff;
  animation: hideProductList 0.9s ease-out;
  position: fixed;
  top: -1000px;
  left: 0;
  right: 0;
  z-index: 2;
}
.productDropdown {
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.productsHeading {
  color: #323b4b;
  font-weight: 700;
  margin-bottom: 2.2rem;
  margin-left: 12px;
  font-size: 1.4rem;
}
.productList {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/* Product Card */
.productBody {
  display: flex;
  align-items: flex-start;
  max-width: 310px;
  padding: 1rem;
  border-radius: 12px;
  min-height: 105px;
  border: 1px solid transparent;
  /* cursor: pointer; */
  margin-bottom: 1rem;
  height: fit-content;
}
.productBody img {
  margin-right: 1.3rem;
  width: 42px;
}
.productTitle {
  color: #323b4b;
  font-weight: 600;
}
.productDescrip {
  color: #4e5d78;
  font-size: 11px;
  margin-bottom: 8px;
}
.productBody:hover {
  border-color: #f3f3f3;
  background: #fafbfc;
}
.comingSoon {
  color: #ffab00;
  border: 1px solid #ffab00;
  background: #fdf4e0;
  border-radius: 5px;
  margin-top: auto;
  width: fit-content;
  font-size: 9px;
  font-weight: 400;
  padding: 1.5px 7px;
  margin-bottom: 6px;
}
.mobileDropdown,
.hamburger {
  display: none;
}
@keyframes showProductList {
  from {
    top: -452px;
  }
  to {
    top: 0;
  }
}
@keyframes hideProductList {
  from {
    top: 0;
  }
  to {
    top: -452px;
  }
}

@media screen and (max-width: 900px) {
  .productList {
    grid-template-columns: repeat(2, 1fr);
  }
  .navParent,
  .navParentHide {
    display: none;
  }
  .mobileDropdown,
  .hamburger {
    display: flex;
  }
  .mobileDropdown {
    max-height: calc(100vh - 370px);
    overflow-y: scroll !important;
  }
  .logoLink {
    margin-left: 4%;
  }
  .hamburger {
    margin: auto 4% auto auto;
    width: 30px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    background: transparent;
    border: none;
    position: relative;
  }
  .hamburger > div {
    width: 100%;
    height: 2px;
    background: #33869d;
    margin: 5px 0;
  }
  .displayList > div.line1 {
    transform: rotate(45deg);
  }
  .displayList > div.line2 {
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    left: 0;
  }
  .fixedNav {
    z-index: 1000;
  }
  .navWrapper {
    align-items: flex-start;
    flex-wrap: wrap;
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .navItemsWrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    display: flex;
    padding: 1.2rem 0;
  }
  .mobileHide {
    display: none;
  }
  .navItems {
    margin: 12px 0;
    padding: 0 4%;
  }
  button.navItems {
    padding: 0 4%;
  }
  .productDropdown {
    flex-direction: column;
    background: #fafbfc;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 2rem 4% 3rem;
  }
  .productsHeading {
    font-size: 1rem;
    margin-bottom: 1.2rem;
  }
  .productBody {
    margin: 0;
  }
  .mobileDropdownShow {
    animation: showMobileProductList 0.5s ease-in-out;
    height: auto;
    overflow: hidden;
  }
  .mobileDropdownHide {
    animation: hideMobileProductList 0.5s ease-out;
    height: 0px;
    overflow: hidden;
    padding: 0;
    margin: 0;
  }
  .hideNav {
    height: 0px;
    padding: 0;
    margin: 0;
  }
  @keyframes showMobileProductList {
    0% {
      height: 0px;
      padding: 2rem 4% 3rem;
    }
    25% {
      height: 50px;
    }
    50% {
      height: 150px;
    }
    75% {
      height: 200px;
    }
    100% {
      height: auto;
    }
  }
  @keyframes hideMobileProductList {
    100% {
      height: 0px;
      padding: 0 4% 0;
      margin: 0;
    }
    75% {
      height: 50px;
    }
    50% {
      height: 150px;
    }
    25% {
      height: 200px;
    }
    0% {
      height: auto;
      padding: 2rem 4% 3rem;
    }
  }
  .logo {
    width: 100px;
  }
}
@media screen and (max-width: 650px) {
  .productList {
    grid-template-columns: 1fr;
  }
}
