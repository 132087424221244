.heroContainer {
  display: flex;
  padding-top: 5.2rem;
  padding-bottom: 4rem;
}
.textSection {
  width: 49%;
}
.textSection p:nth-child(1) {
  color: #33869d;
  font-size: 18px;
  font-family: "Helvetica Neue";
  font-weight: 500;
  line-height: 2;
}
.textSection h1 {
  color: #323b4b;
  font-weight: 700;
  max-width: 300px;
  font-size: 2.8rem;
  margin-bottom: 1.8rem;
}
.textSection p:nth-child(3) {
  color: #4e5d78;
  letter-spacing: 0.5px;
}
.formSection {
  width: 51%;
}
.formSection h4 {
  color: #323b4b;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.15rem;
  margin-bottom: 6px;
  line-height: 1.8;
  letter-spacing: 0.2px;
}
.formSection > p {
  letter-spacing: 0.5px;
}
.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1rem;
  max-width: 450px;
}
.formContainer > div {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.formContainer > div > label {
  font-size: 13px;
  opacity: 0.8;
  font-weight: 300;
}
.formContainer > div > input,
.formContainer > div > textarea {
  border: 1px solid #c1c7d0;
  background: transparent;
  border-radius: 10px;
  padding-left: 10px;
}
.formContainer > div > input {
  height: 48px;
}
.formContainer > div > textarea {
  padding: 1rem;
  max-height: 150px;
  min-height: 150px;
}
.halfInput {
  width: 47%;
}
.fullInput {
  width: 100%;
}
.btn {
  width: 100%;
  margin-top: 2rem;
}

.formContainer input.empty {
  border-color: red;
}
.inputMessage {
  text-align: left;
  font-size: 10px;
  margin-top: -1rem;
  color: red;
}
p.message {
  margin: 1rem auto 0;
  font-size: 14px;
  color: red;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .textSection p:nth-child(1) {
    font-size: 14px;
    font-family: 'Euclid';
    font-weight: 400;
    margin-bottom: 4px;
  }
  .textSection h1 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
  .textSection p:nth-child(3) {
    font-size: 14px;
    letter-spacing: normal;
    color: #8A94A6;
  }
}

@media screen and (max-width: 800px) {
  .heroContainer {
    flex-direction: column;
    padding-bottom: 2rem;
    padding-top: 3rem;
  }
  .textSection {
    width: 100%;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 1.8rem;
    margin-bottom: 1.8rem;
  }
  .formSection {
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 2.8rem;
  }
  .formContainer {
    max-width: unset;
  }
  .textSection h1 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 600px) {
  .textSection h1 {
    font-size: 1.5rem;
    max-width: 250px;
  }
  .textSection p:nth-child(3) {
    font-size: 13px;
  }
  .halfInput {
    width: 100%;
  }
}