.footer-bg {
  background-color: #051024;
  background-image: url("../../../../assets/images/footerImage.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  padding-bottom: 12rem;
}
.container {
  padding-top: calc(7rem + 107px);
  padding-bottom: 3rem;
  font-weight: 300;
  position: relative;
  margin-top: 107px;
}
.grid {
  display: grid;
  grid-template-columns: 1.8fr 1fr 1fr 1.2fr;
  gap: 1rem;
}
.grid-1 {
}
.grid-2 {
}
.grid-3 {
}
.grid-4 {
}
.logo {
  width: 78px;
  margin-bottom: 1rem;
}
.grid-1-txt {
  color: #e9f7fe90;
  font-size: 13px;
  line-height: 28px;
  width: 85%;
  letter-spacing: 0.3px;
}
.socials {
  padding-top: 10px;
}
.socials-txt {
}
.grid-item-hd {
  color: #8c97ac;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.2px;
}
.socials-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 180px;
}
.socials-icons svg {
  width: 24px;
  cursor: pointer;
}
.grid-item-list {
  list-style-type: none;
  padding-left: 0;
}
.grid-item-list li {
  font-size: 13.5px;
  line-height: 36px;
  color: #e9f7fe;
  font-weight: 200;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.grid-3-txt {
  color: #e9f7fe;
  font-size: 14px;
  line-height: 26px;
}
.grid-3-txt1 {
  font-size: 13.5px;
}
.grid-3-link {
  font-size: 14px;
  color: #ffd166;
  display: flex;
  line-height: 26px;
  align-items: center;
}
.grid-3-link:hover {
  color: #ffd166;
  transform: scale(1.05);
  text-decoration: none;
}
.grid-3-link svg {
  width: 15px;
  margin-right: 10px;
}
.container hr {
  margin: 2.8rem 0 0.6rem;
  border-color: #313d55;
}
.container small {
  color: #e9f7fe;
  font-weight: 300;
  font-size: 85%;
  letter-spacing: 0.2px;
}
.container li a {
  color: inherit !important;
  text-decoration: none !important;
}
.grid-item-list li:hover {
  transform: scale(1.05);
}
.numbers {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.numbers svg {
  width: 24px;
  margin-right: 10px;
}
.numbers div {
  display: flex;
  flex-direction: column;
}
.footerBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.arrowUp {
  background: #42577d;
  border-radius: 50%;
  padding: 10px;
  width: 44px;
  height: 44px;
}
@media screen and (min-width: 1400px) {
  .footer-bg {
    padding-bottom: 15%;
  }
}
@media (max-width: 1000px) {
  .container {
    padding-top: calc(5rem + 107px);
  }
  .grid {
    gap: 5px;
  }
  .footer-bg {
    padding-bottom: 8rem;
  }
}

@media (max-width: 800px) {
  .footer-bg {
    background-size: auto;
    padding-bottom: 11rem;
  }
  .grid {
    gap: 1rem;
    grid-template-columns: 1fr;
    text-align: left;
    position: relative;
    padding-bottom: 5.5rem;
  }
  .grid-1-txt {
    width: 100%;
    max-width: 350px;
    margin-bottom: 1rem;
    padding-top: 10px;
  }
  .grid-3-txt {
    width: 100%;
    max-width: 250px;
    margin-bottom: 1rem;
  }
  .container small {
    width: 100%;
    display: block;
    text-align: center;
    padding: 1rem 0;
  }
  .socials {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .numbers {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 6px;
  }
  .numbers svg, .grid-3-link svg {
    margin-bottom: 6px;
  }
  .grid-3-link {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Still Joining Card */
.cardWrapper {
  position: absolute;
  background-color: #33869d;
  background-image: url("../../../../assets/vectors/footerCardBg.svg");
  color: #fff;
  width: 100%;
  padding: 1.8rem;
  text-align: center;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 60% 100%;
  top: -107px;
  left: 0;
}
.cardWrapper h5 {
  font-size: 1.75rem;
  font-weight: 700;
  letter-spacing: 0.3px;
  margin-bottom: 10px;
}
.cardWrapper p {
  font-size: 15px;
  margin-bottom: 2rem;
  letter-spacing: 0.3px;
}

@media screen and (max-width: 1200px) {
  .cardWrapper {
    width: 90%;
    left: 5%;
  }
}

@media screen and (max-width: 600px) {
  .cardWrapper {
    padding-top: 2.2rem;
    padding-bottom: 2rem;
    background-size: contain;
    background-position: bottom right;
  }
  .cardWrapper > h5 {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .cardWrapper p {
    font-size: 13px;
  }
  .arrowUp {
    position: absolute;
    right: 1rem;
    width: 36px;
    height: 37px;
    bottom: 22rem;
  }
}
