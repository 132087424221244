.contactContainer {
  padding-top: 2rem;
  padding-bottom: 5.5rem;
  justify-content: space-between;
  display: flex;
  text-align: center;
  max-width: 860px;
}
.cardContainer > div {
  width: fit-content;
  margin: auto;
  margin-bottom: 1.5rem;
  background: #ebf3f5;
  border-radius: 50%;
  padding: 8px;
}
.cardContainer > div > svg {
  border-radius: 50%;
  background: #d9f7ff;
  border: 1px solid #33869d;
  padding: 13px;
  width: 50px;
  height: 50px;
}
.cardContainer p:nth-child(2) {
  text-transform: uppercase;
  color: #323b4b;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
}
.cardContainer p:nth-last-child(2) {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.3px;
}
.cardContainer p:nth-last-child(1) {
  color: #33869d;
  line-height: 2;
  font-size: 15px;
  font-weight: 300;
  max-width: 200px;
}
.cardContainer a {
  color: inherit;
  text-decoration: none;
}
.cardContainer a:hover {
  text-decoration: underline !important;
}

@media screen and (max-width: 800px) {
  .contactContainer {
    flex-direction: column;
    padding-bottom: 1rem;
  }
  .cardContainer {
    margin-bottom: 4rem;
  }
  .cardContainer p:nth-last-child(1) {
    margin: auto;
  }
}
