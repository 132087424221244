.founderBg {
  background-color: #051024;
  background-image: url("../../../assets/vectors/founderBg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
}
.founderContainer {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  justify-content: space-between;
  align-items: center;
}
.founderContainer > img {
  width: 40%;
}
.founderContainer > div {
  width: 50%;
}
.founderContainer > div > h3 {
  letter-spacing: 1px;
  font-weight: 700;
  line-height: 2;
  margin-bottom: 1.6rem;
  color: #fafbfc;
}
.founderContainer > div > p {
  line-height: 2;
  font-size: 16px;
  color: #ebf3f5;
  max-width: 435px;
  margin-bottom: 4rem;
  letter-spacing: 0.22px;
  font-weight: 300;
}
.founderContainer > div > div > a {
  margin-right: 1.5rem;
}

@media screen and (max-width: 1200) {
  .founderContainer > h3 {
    font-size: 1.5rem;
  }
  .founderContainer > p {
    font-size: 14px;
    max-width: 550px;
  }
}

@media screen and (max-width: 900px) {
  .founderContainer {
    flex-direction: column;
    padding-bottom: 5rem;
  }
  .founderContainer > img {
    width: 96%;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2.4rem;
  } 
  .founderContainer > div {
    width: 100%;
  }
  .founderBg {
    background-size: cover;
    background-position-x: 55%;
  }
  .founderContainer > div > p {
    font-size: 14px;
    max-width: 550px;
    line-height: 2.5;
  }
}
